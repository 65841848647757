<template>
  <!-- eslint-disable tailwindcss/no-custom-classname -->
  <div
    class="relative overflow-hidden pb-7 pl-7 pr-14 pt-6 text-white"
    :class="[
      { gradient: !props.isFooter },
      {
        '!p-0 md:w-1/2': props.isFooter,
      },
    ]"
  >
    <div class="relative z-20">
      <p
        v-if="props.title"
        class="mb-6 text-xl font-bold leading-6 xl:text-[1.875rem]"
      >
        {{ props.title }}
      </p>
      <p
        v-if="props.description"
        class="mb-6 w-60 text-base font-normal"
      >
        {{ props.description }}
      </p>
      <div
        v-for="box in props.boxes"
        :key="box.id"
        class="mb-2.5 flex items-center last:mb-0"
      >
        <UiIconBoxItem
          :title="box.content?.header"
          :description="box.content?.description"
          :icon="box.content?.icon"
        />
      </div>
      <UiButton
        v-if="props.buttonLink"
        :to="props.buttonLink"
        icon-right="arrow"
        class="mt-10 w-full"
      >
        {{ props.buttonText }}
      </UiButton>
    </div>
    <UiIcon
      v-if="!props.isFooter"
      name="logo-icon-box"
      :width="410"
      :height="500"
      class="absolute left-0 top-0 block w-full"
    />
  </div>
</template>

<script setup lang="ts">
import UiIcon from '../UiIcon/UiIcon.vue'
import UiButton from '../UiButton/UiButton.vue'
import UiIconBoxItem from './UiIconBoxItem/UiIconBoxItem.vue'
import type { IconBox } from './UiIconBox.types'

interface UiIconBox {
  boxes: IconBox[]
  title: string
  description: string
  buttonLink?: string
  buttonText?: string
  isFooter?: boolean
}

const props = withDefaults(defineProps<UiIconBox>(), {
  buttonLink: '',
  buttonText: '',
  isFooter: false,
})
</script>

<style lang="postcss" scoped>
.gradient {
  background: linear-gradient(90deg, #003366 0.73%, #1d62a7 100%);
}
</style>
